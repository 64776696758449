<template>
  <div>

    <google-map-auto-complete
      :address="address"
      @search-location="searchLocation"
    />

    <google-map
      class="mt-1"
      :position="position"
    >
      <template v-slot:marker>
        <google-map-marker
          v-if="position.lat && position.lng"
          :position="position"
          @update-coordinates="updateCoordinates"
        />
      </template>
    </google-map>

  </div>
</template>

<script>
import GoogleMap from '../GoogleMap.vue'
import GoogleMapAutoComplete from '../GoogleMapAutoComplete.vue'
import GoogleMapMarker from '../GoogleMapMarker.vue'

export default {
  components: { GoogleMapAutoComplete, GoogleMap, GoogleMapMarker },
  props: {
    address: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const searchLocation = location => {
      emit('search-location', location)
    }
    const updateCoordinates = location => {
      emit('update-coordinates', location)
    }

    return {
      searchLocation,
      updateCoordinates,
    }
  },
}
</script>
