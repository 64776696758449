<template>
  <gmap-map
    ref="map"
    :center="position"
    :zoom="12"
    map-type-id="terrain"
    :options="{disableDefaultUI:false}"
    style="min-height: 500px"
  >
    <slot name="marker" />
  </gmap-map>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
}
</script>
