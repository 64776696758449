import { ref, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import activityStoreModule from '../activityStoreModule'

export default function useActivitiesForm() {
  const ACTIVITY_APP_STORE_MODULE_NAME = 'app-activity'

  // Register module
  if (!store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_APP_STORE_MODULE_NAME, activityStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_APP_STORE_MODULE_NAME)
  })

  const blankActivityData = {
    description: '',
    descriptionAr: '',
    isSubActivity: false,
    activityId: '',
  }

  const activityData = ref(JSON.parse(JSON.stringify(blankActivityData)))

  store.dispatch('app-activity/fetchActivities')

  const fetchSubActivities = activityId => store.dispatch('app-activity/fetchActivity', activityId)

  const fetchSubActivity = async id => {
    try {
      const response = await store.dispatch('app-activity/fetchSubActivity', id)
      // eslint-disable-next-line prefer-destructuring
      activityData.value = response.data.data[0]
      activityData.value.isSubActivity = true
      activityData.value.subactivityId = id
    } catch (error) {
      console.error(error)
    }
  }

  const updateSubActivity = async () => {
    try {
      const response = await store.dispatch('app-activity/updateSubActivity', activityData.value)

      if (response.data.status) {
        router.push({ name: 'general-settings-activities-list' })
      }
    } catch (error) {
      console.error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const activityOptions = computed(() => store.getters['app-activity/activityOptions'])

  //  Resolve Activity
  const resolveActivity = activityId => {
    const activity = activityOptions.value.find(obj => (obj.value === activityId))
    return activity ? activity.label : activityId
  }

  //   Resolve Sub Activity
  const subActivityOptions = computed(() => store.getters['app-activity/subActivityOptions'])

  const resolveSubActivity = subActivityId => {
    const subActivity = subActivityOptions.value.find(obj => (obj.value === subActivityId))
    return subActivity ? subActivity.label : subActivityId
  }

  return {
    //  Data
    activityData,

    fetchSubActivities,
    fetchSubActivity,
    updateSubActivity,

    // Options
    activityOptions,
    subActivityOptions,

    //  UI
    resolveActivity,
    resolveSubActivity,
  }
}
