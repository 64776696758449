import {
  fetchBranchAPI,
  fetchBranchesAPI,
  fetchCashierAPI,
  fetchRetailersAPI,
  fetchRetailerSubscriptionAPI,
  manageRetailerDeviceAPI,
  procBranchAPI,
  updateBranchAPI,
  updateCashierAPI,
  updateRetailerAPI,
  updateRetailerSubscriptionAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {
    retailers: [],
  },
  getters: {
    retailerOptions(state) {
      if (state.retailers.length <= 0) {
        return []
      }
      return state.retailers.map(obj => ({
        label: obj.BusinessName,
        value: obj.entityId,
      }))
    },
  },
  mutations: {
    SET_RETAILER_OPTIONS(state, data) {
      state.retailers = data
    },
  },
  actions: {
    // *===============================================---*
    // *--------- RETAILERS ---- ---------------------------------------*
    // *===============================================---*
    async fetchRetailers(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchRetailersAPI, { params: queryParams })
        ctx.commit('SET_RETAILER_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchRetailer(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchRetailersAPI}/${params}`)
        return response
      } catch (error) {
        return error
      }
    },
    async updateRetailer(ctx, params) {
      try {
        const response = await this.$axios.post(updateRetailerAPI, params)
        return response
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- BRANCHES ---- ---------------------------------------*
    // *===============================================---*
    async fetchBranches(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchBranchesAPI}/${params}`)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchBranch(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchBranchAPI}/${params}`)
        return response
      } catch (error) {
        return error
      }
    },
    async updateBranch(ctx, params) {
      try {
        return await this.$axios.post(updateBranchAPI, params)
      } catch (error) {
        return error
      }
    },
    async procBranch(ctx, params) {
      try {
        return await this.$axios.post(procBranchAPI, params)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- CASHIERS ---- ---------------------------------------*
    // *===============================================---*
    async fetchCashier(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchCashierAPI}/${params}`)
        return response
      } catch (error) {
        return error
      }
    },
    async updateCashier(ctx, params) {
      try {
        return await this.$axios.post(updateCashierAPI, params)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- SUBSCRIPTIONS ---- ---------------------------------------*
    // *===============================================---*
    async fetchRetailerSubscription(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchRetailerSubscriptionAPI}/${params}`)
        return response
      } catch (error) {
        return error
      }
    },
    async updateRetailerSubscription(ctx, params) {
      try {
        return await this.$axios.post(updateRetailerSubscriptionAPI, params)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- DEVICES ---- ---------------------------------------*
    // *===============================================---*
    async manageRetailerDevice(ctx, params) {
      try {
        return await this.$axios.post(manageRetailerDeviceAPI, params)
      } catch (error) {
        return error
      }
    },
  },
}
