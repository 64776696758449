<template>
  <div>
    <b-card>
      <validation-observer
        #default="{ invalid, handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form @submit.prevent="handleSubmit(updateBranch)">

          <!-- Actions -->
          <b-row class="mb-4">
            <b-col>
              <b-button
                variant="secondary"
                class="float-right"
                size="sm"
                type="submit"
                :disabled="invalid"
              >
                <span class="text-nowrap">Save Changes</span>
              </b-button>
            </b-col>
          </b-row>

          <branches-form
            :activity-options="activityOptions"
            :sub-activity-options="subActivityOptions"
            :catalogue-type-options="catalogueTypeOptions"
            :branch-data="branchData"
            :branch-data-geo-location.sync="branchDataGeoLocation"
            :branch-data-position.sync="branchDataPosition"
            :branch-data-address="branchDataAddress"
            @search-location="searchLocation"
            @update-coordinates="updateCoordinates"
            @fetch-sub-activities="fetchSubActivities"
          />

        </b-form>

      </validation-observer>
    </b-card>

    <!-- Cashiers -->
    <b-card
      v-if="branchData.cashiers && branchData.cashiers.length > 0"
      no-body
    >
      <b-row>
        <b-col class="mt-2">

          <h4 class="px-2 mb-2">
            Cashiers
          </h4>

          <cashiers-list :cashiers="branchData.cashiers" />

        </b-col>
      </b-row>
    </b-card>

    <!-- Devices -->
    <b-card
      v-if="branchData.products && branchData.products.length > 0"
      no-body
    >
      <b-row>
        <b-col class="mt-2">

          <h4 class="px-2 mb-2">
            Devices
          </h4>

          <devices-list
            :devices="branchData.products"
            :table-columns="tableColumns"
          />

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import useBranchesForm from '../branches-form/useBranchesForm'
import retailerStoreModule from '../../retailerStoreModule'
import BranchesForm from '../branches-form/BranchesForm.vue'
import useActivitiesForm from '@/views/general-settings/activities-and-subactivities/activities-form/useActivitiesForm'
import CashiersList from '../../cashiers/cashiers-list/CashiersList.vue'
import DevicesList from '../../devices/devices-list/DevicesList.vue'
import useDevicesList from '../../devices/devices-list/useDevicesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    BranchesForm,
    CashiersList,
    DevicesList,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const RETAILER_APP_STORE_MODULE_NAME = 'app-retailer'

    // Register module
    if (!store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_APP_STORE_MODULE_NAME, retailerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RETAILER_APP_STORE_MODULE_NAME)) store.unregisterModule(RETAILER_APP_STORE_MODULE_NAME)
    })

    const {
      branchData,
      branchDataGeoLocation,
      branchDataPosition,
      branchDataAddress,

      catalogueTypeOptions,

      searchLocation,
      updateCoordinates,

      fetchBranch,
      updateBranch,
    } = useBranchesForm()

    fetchBranch(router.currentRoute.params.id)

    const {
      activityOptions,
      subActivityOptions,

      fetchSubActivities,
    } = useActivitiesForm()

    const {
      tableColumns,
    } = useDevicesList()

    return {
      //  Data
      branchData,
      branchDataGeoLocation,
      branchDataPosition,
      branchDataAddress,

      tableColumns,

      searchLocation,
      updateCoordinates,

      fetchSubActivities,

      //  Action
      updateBranch,

      //  UI
      activityOptions,
      subActivityOptions,
      catalogueTypeOptions,
    }
  },
}
</script>
