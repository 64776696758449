<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <div
        v-if="$route.name === 'retailers-management-retailer-subscriptions-edit'"
        class="m-2"
      >

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="initiateAdd({ branchId, products: [{ operation: 'A', quantity: null, deviceId: null }] })"
              >
                <span class="text-nowrap">Add Device</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refDeviceListTable"
        class="position-relative"
        :items="devices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- Edit -->
            <b-dropdown-item @click="initiateEdit({ branchId: data.item.branchId, products: [{ operation: 'E', deviceId:data.item.deviceId, quantity: data.item.quantity }] })">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- Delete -->
            <b-dropdown-item @click="initiateDelete({ branchId: data.item.branchId, products: [{operation: 'D', deviceId: data.item.deviceId}] })">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="35"
                :src="data.item.details.thumbnailPhotoUrl"
                :icon="'laptop'"
                variant="light-primary"
              />
            </template>
            <span class="text-capitalize">{{ data.item.details.name }}</span>
            <br>
            <small class="text-muted">{{ data.item.details.description }}</small>
          </b-media>
        </template>

      </b-table>

    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BMedia, BAvatar, BButton, BIconLaptop,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    BButton,
    BIconLaptop,
  },
  directives: {
    Ripple,
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    branchId: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_, { emit }) {
    const initiateDelete = payload => {
      emit('initiate-delete', payload)
    }

    const initiateEdit = payload => {
      emit('initiate-edit', payload)
    }

    const initiateAdd = payload => {
      emit('initiate-add', payload)
    }

    return {
      initiateDelete,
      initiateEdit,
      initiateAdd,
    }
  },
}
</script>
