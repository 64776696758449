<template>
  <gmap-marker
    :position="position"
    :draggable="draggable"
    @dragend="updateCoordinates"
  />
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const updateCoordinates = location => {
      emit('update-coordinates', location)
    }
    return {
      updateCoordinates,
    }
  },

}
</script>
