<template>
  <gmap-autocomplete
    :value="address"
    class="form-control"
    placeholder="Enter Address"
    @place_changed="searchLocation"
  />
</template>

<script>

export default {
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const searchLocation = location => {
      emit('search-location', location)
    }

    return {
      searchLocation,
    }
  },
}
</script>
