<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        ref="refCashierListTable"
        class="position-relative"
        :items="cashiers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('E', 'Retailers & Branches')"
              :to="{ name: 'retailers-management-retailer-cashiers-edit', params: { id: data.item.cashierId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <template #cell(pin)="data">
          <span
            v-if="togglePin"
            class="mx-2"
          >
            {{ data.item.pin }}
          </span>
          <b-button
            v-ripple.400="'rgba(82, 67, 170, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="btn-icon"
            @click="togglePin = !togglePin"
          >
            <feather-icon :icon="togglePin ? 'EyeOffIcon' : 'EyeIcon'" />
          </b-button>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCashiersList from './useCashiersList'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    cashiers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      togglePin: false,
    }
  },
  setup() {
    const {
      tableColumns,
    } = useCashiersList()

    return {
      tableColumns,
    }
  },
}
</script>
