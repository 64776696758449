import {
  fetchActivitiesAPI,
  createActivityAPI,
  updateActivityAPI,
  deleteActivityAPI,

  createSubActivityAPI,
  updateSubActivityAPI,
  deleteSubActivityAPI,
  fetchSubActivityAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {
    activities: [],
    subActivities: [],
  },
  getters: {
    activityOptions(state) {
      if (state.activities.length <= 0) {
        return []
      }
      return state.activities.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    },

    subActivityOptions(state) {
      if (state.subActivities.length <= 0) {
        return []
      }
      return state.subActivities.map(obj => ({
        label: obj.description,
        value: obj.subactivityId,
      }))
    },
  },
  mutations: {
    SET_ACTIVITY_OPTIONS(state, data) {
      state.activities = data
    },
    SET_SUB_ACTIVITY_OPTIONS(state, data) {
      state.subActivities = data
    },
  },
  actions: {
    // *===============================================---*
    // *--------- ACTIVITIES ---- ---------------------------------------*
    // *===============================================---*
    async fetchActivities(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchActivitiesAPI, { params: queryParams })
        ctx.commit('SET_ACTIVITY_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchActivity(ctx, params) {
      try {
        const response = await this.$axios.get(`${fetchActivitiesAPI}/${params}`)
        ctx.commit('SET_SUB_ACTIVITY_OPTIONS', response.data.data[0].subactvities)
        return response
      } catch (error) {
        return error
      }
    },
    async createActivity(ctx, params) {
      try {
        return await this.$axios.post(createActivityAPI, params)
      } catch (error) {
        return error
      }
    },
    async updateActivity(ctx, params) {
      try {
        return await this.$axios.post(updateActivityAPI, params)
      } catch (error) {
        return error
      }
    },
    async deleteActivity(ctx, deleteParams) {
      try {
        return await this.$axios.post(deleteActivityAPI, deleteParams)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- SUB ACTIVITIES ---- ---------------------------------------*
    // *===============================================---*
    async fetchSubActivity(ctx, params) {
      try {
        return await this.$axios.get(`${fetchSubActivityAPI}/${params}`)
      } catch (error) {
        return error
      }
    },
    async createSubActivity(ctx, params) {
      try {
        return await this.$axios.post(createSubActivityAPI, params)
      } catch (error) {
        return error
      }
    },
    async updateSubActivity(ctx, params) {
      try {
        return await this.$axios.post(updateSubActivityAPI, params)
      } catch (error) {
        return error
      }
    },
    async deleteSubActivity(ctx, deleteParams) {
      try {
        return await this.$axios.post(deleteSubActivityAPI, deleteParams)
      } catch (error) {
        return error
      }
    },
  },
}
