<template>
  <b-row>

    <!-- Branch Name -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Branch Name"
        rules="required"
      >
        <b-form-group
          label="Branch Name"
          label-for="branch-name"
        >
          <b-form-input
            id="branch-name"
            v-model="branchData.name"
            :state="getValidationState(validationContext)"
            placeholder="Branch Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Latitude & Longitude -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Geo Location"
        rules="required"
      >
        <b-form-group
          label="Geo Location"
          label-for="geo-location"
        >
          <b-form-input
            id="geo-location"
            :value="branchDataGeoLocation"
            :state="getValidationState(validationContext)"
            placeholder="Branch Address (Geo Location)"
            @input="(val) => $emit('update:branchDataGeoLocation', val)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Map -->
    <b-col
      v-if="branchData.latitude"
      sm="12"
      class="mb-1"
    >
      <auto-complete-google-map
        :address="branchDataAddress"
        :position="branchDataPosition"
        @search-location="searchLocation"
        @update-coordinates="updateCoordinates"
      />
    </b-col>

    <!-- Activity -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Activity"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Activity"
          label-for="activity"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="branchData.activityId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="activityOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="activity"
            disabled
            @input="fetchSubActivities(branchData.activityId)"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Sub Activity -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Sub Activity"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Sub Activity"
          label-for="sub-activity"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="branchData.subactivityId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="subActivityOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="sub-activity"
            disabled
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Catalogue Type -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Catalogue Type"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Catalogue Type"
          label-for="catalogue-type"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="branchData.requireFreshCatalog"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="catalogueTypeOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="catalogue-type"
            disabled
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import AutoCompleteGoogleMap from '@/components/google-maps/auto-complete-google-map/AutoCompleteGoogleMap.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,

    //  Form Validation
    ValidationProvider,
    AutoCompleteGoogleMap,
  },

  directives: {
    Ripple,
  },
  props: {
    activityOptions: {
      type: Array,
      required: true,
    },
    subActivityOptions: {
      type: Array,
      required: true,
    },
    catalogueTypeOptions: {
      type: Array,
      required: true,
    },
    branchData: {
      type: Object,
      required: true,
    },
    branchDataGeoLocation: {
      type: [String, Number],
      required: false,
      default: '',
    },
    branchDataPosition: {
      type: Object,
      required: true,
    },
    branchDataAddress: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const {
      getValidationState,
    } = formValidation()

    const searchLocation = location => {
      emit('search-location', location)
    }
    const updateCoordinates = location => {
      emit('update-coordinates', location)
    }

    const fetchSubActivities = activityId => {
      emit('fetch-sub-activities', activityId)
    }

    return {
      //  Form
      getValidationState,

      searchLocation,
      updateCoordinates,

      fetchSubActivities,
    }
  },
}
</script>
