import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useActivitiesForm from '@/views/general-settings/activities-and-subactivities/activities-form/useActivitiesForm'

export default function useBranchesForm() {
  const branchesData = ref([])
  const branchData = ref({})
  const branchDataGeoLocation = ref('')
  const branchDataPosition = ref({})
  const branchDataAddress = ref('')
  const catalogueTypeOptions = [
    {
      label: 'Custom',
      value: 0,
    },
    {
      label: 'Fresh',
      value: 1,
    },
  ]

  const { fetchSubActivities } = useActivitiesForm()

  const setGeoLocation = () => {
    branchDataGeoLocation.value = `${parseFloat(branchData.value.latitude)}, ${parseFloat(branchData.value.longitude)}`
  }

  const setPosition = () => {
    branchDataPosition.value = { lat: parseFloat(branchData.value.latitude), lng: parseFloat(branchData.value.longitude) }
  }

  const setLatLng = (lat, lng) => {
    branchData.value.latitude = parseFloat(lat)
    branchData.value.longitude = parseFloat(lng)
  }

  const setAddress = type => {
    const geocoder = new google.maps.Geocoder

    geocoder.geocode({ location: branchDataPosition.value }, (results, status) => {
      if (status !== 'OK') {
        branchData.latitude = ''
        branchData.longitude = ''
        branchDataAddress.value = ''
        branchDataPosition.value = { lat: '', lng: '' }
        return
      }
      if (results[0]) {
        if (!type) {
          setLatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng())
        }
        branchDataPosition.value = { lat: parseFloat(branchData.value.latitude), lng: parseFloat(branchData.value.longitude) }
        branchDataAddress.value = results[0].formatted_address
      }
    })
  }

  const searchLocation = location => {
    setLatLng(location.geometry.location.lat(), location.geometry.location.lng())
    branchDataGeoLocation.value = `${parseFloat(branchData.value.latitude)}, ${parseFloat(branchData.value.longitude)}`
    setGeoLocation()
    setPosition()
  }

  const updateCoordinates = location => {
    setLatLng(location.latLng.lat(), location.latLng.lng())
    setGeoLocation()
    setPosition()
  }

  watch([branchDataGeoLocation], () => {
    const LatLng = branchDataGeoLocation.value.split(',')
    setLatLng(LatLng[0], LatLng[1])
    setPosition()
    setAddress(1)
  })

  const fetchBranches = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchBranches', params)
      branchesData.value = response.data.data
      return response
    } catch (error) {
      branchesData.value = undefined
      return error
    }
  }

  const fetchBranch = async params => {
    try {
      const response = await store.dispatch('app-retailer/fetchBranch', params)
      branchData.value = response.data.data[0]

      if (branchData.value.latitude && branchData.value.longitude) {
        setPosition()
        setGeoLocation()
      }

      fetchSubActivities(branchData.value.activityId)

      return response
    } catch (error) {
      console.error(error)
      branchData.value = undefined
      return error
    }
  }

  const updateBranch = async () => {
    try {
      const response = await store.dispatch('app-retailer/updateBranch', branchData.value)
      router.push({ name: 'retailers-management-retailers-list' })
      return response
    } catch (error) {
      return error
    }
  }

  return {
    branchesData,
    branchData,
    branchDataGeoLocation,
    branchDataPosition,
    branchDataAddress,

    searchLocation,
    updateCoordinates,

    fetchBranches,
    fetchBranch,
    updateBranch,

    catalogueTypeOptions,
  }
}
