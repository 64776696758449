export default function useDevicesList() {
  // Table Handlers
  const tableColumns = [
    { key: 'actions' },
    { key: 'name', sortable: true },
    { key: 'details.price', sortable: true, label: 'Price' },
    { key: 'quantity', sortable: true },
  ]

  const tableColumnsWithoutAction = tableColumns.slice(1)

  return {
    tableColumns,
    tableColumnsWithoutAction,
  }
}
